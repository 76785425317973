import React from "react";
import { View, Image, Dimensions, Platform } from "react-native";
import { Feather } from '@expo/vector-icons';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { useNavigation } from '@react-navigation/native';
import { Auth } from 'aws-amplify';
import { TouchableOpacity } from "react-native-gesture-handler";

const { width } = Dimensions.get('screen');
let scale = 1

// Adjust header elements for viewing the web portal on smaller devices
if (width < 800 ) {
    scale = .8
  }

  
const Header = (props) => {
    const navigation = useNavigation();
    return (
        <View style={{ paddingHorizontal: wp(2.7), paddingVertical: hp(1.8), paddingTop: hp(5), backgroundColor: '#000', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
            <TouchableOpacity style={styles.button} onPress={() => { if(props.type === 'pop'){ navigation.pop()} else{ Auth.signOut(); navigation.navigate('Login') }}}>
                <Feather name={props.type === 'pop' ? 'corner-up-left' : 'log-out'} size={50 * scale} color={'#FFF'} />
            </TouchableOpacity>
            <Image source={require('../assets/images/logo-white.png')} style={{ maxWidth: 250, width: wp(50), height: 54}}/>
            <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
            {props.hasOwnProperty("organization") && <TouchableOpacity style={styles.button} onPress={() => {navigation.navigate('Settings', props)}}>
                <Feather name='user' size={50 * scale} color={'#FFF'} /> 
            </TouchableOpacity> }
            {props.hasOwnProperty("organization") && <TouchableOpacity style={[styles.button, {marginLeft: 5}]} onPress={() => {navigation.navigate('SubmitBug', props)}}>
                <Feather name='help-circle' size={30 * scale} color={'#FFF'} />
            </TouchableOpacity> }
            </View>
        </View>
    )    
}

const styles = {
    button: {
        cursor: 'pointer'
    }
}

export default Header;