import React from "react";
import { TextInput, View } from "react-native";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { fonts } from "../style/fonts";
import { responsiveText } from '../style/fontSizes';

const Textbox = (props) => {
    return (
        <View style={{ borderRadius: 12, backgroundColor: 'rgba(255, 196, 81, 0.3)', height: hp(6.8) }}>
            <TextInput adjustsFontSizeToFit={true} allowFontScaling={false} keyboardType={props.keyboardType != undefined ? props.keyboardType : 'default'} value={props.value} onChangeText={(text) => { props.onChangeText != undefined && props.onChangeText(text) }} placeholder={props.placeholder} placeholderTextColor={'rgba(0,0,0,0.35)'} style={{ flex: 1, fontFamily: fonts.POPPINS.medium, fontSize: 24, textAlign: 'center', color: '#000', marginBottom: -4 }} />
        </View>
    )
}

export default Textbox;