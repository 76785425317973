import React from 'react';
import { Platform, Dimensions, View, StyleSheet, TextInput } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { fonts } from "../style/fonts";

const screenWidth = Dimensions.get('window').width


export default function AppTextInput({ leftIcon, boxStyle = null, textStyle = null, ...otherProps }) {
  return (
    <View style={boxStyle ? boxStyle : styles.container}>
      {leftIcon && (
        <MaterialCommunityIcons
          name={leftIcon}
          size={20}
          color="#6e6869"
          style={styles.icon}
        />
      )}
      <TextInput
        adjustsFontSizeToFit={true} 
        allowFontScaling={false} 
        style={textStyle ? textStyle : styles.input}
        placeholderTextColor="#6e6869"
        {...otherProps}
      />
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    backgroundColor: '#f9f9f9',
    borderRadius: 12,
    flexDirection: 'row',
    padding: 15,
    marginVertical: 10,
    width: Platform.OS === 'web' && screenWidth >= 1000 ? wp(40) : wp(80)
  },
  icon: {
    marginRight: 10
  },
  input: {
    width: '100%',
    fontSize: 22,
    color: '#101010',
    fontFamily: fonts.POPPINS.medium,
  }
});