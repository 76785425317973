import React from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import { Feather } from '@expo/vector-icons';

/*
Props:
onChangeValue(): callback for onpress



*/



const Checkbox = (props) => {
    return (
        <TouchableWithoutFeedback onPress={() => { props.onChangeValue != undefined && props.onChangeValue(!props.selected) }}>
            <View style={{ justifyContent: 'center', alignItems: 'center', borderRadius: 2, borderWidth: 2, borderColor: !props.selected ? '#000' : '#FFC451', backgroundColor: !props.selected ? '#FFF' : '#FFC451', height: 20, width: 20 }}>
                <Feather name='check' size={16} color={'#FFF'} />
            </View>
        </TouchableWithoutFeedback>
    )
}

export default Checkbox;