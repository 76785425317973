import {StyleSheet} from 'react-native';

const mobileStyles = StyleSheet.create({
    container: {
        flex:1,
        backgroundColor: "#FFF",
        width: '100%'
    },
    commentsContainer: {
        width: '100%', 
        alignSelf: 'center',
        flex: 1
      },
      commentContainer: {
        marginTop: 20, 
        maxWidth: '80%',
        flex: 1
      },
      secondaryCommentContainer: {
        alignSelf: 'flex-end'
      },
      secondaryCommentUserName: {
        fontWeight: 'bold',
        alignSelf: 'flex-end'
      },
      secondaryCommentDateTime: {
        fontWeight: 'bold',
        alignSelf: 'flex-end',
        color: 'rgba(0, 0, 0, .4)'
      },
      secondaryComment: {
        backgroundColor: 'lightgreen', 
        alignSelf: 'flex-end',
        width: '100%',
      },
})

export default mobileStyles;