import React, { useState, useEffect } from "react"
import { View, StyleSheet, TouchableWithoutFeedback, ScrollView, TextInput, Platform, Keyboard } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import CustomTextElipses from "../components/CustomTextElipses";
import Header from "../components/Header";
import Separator from '../components/Separator';
import { Feather } from '@expo/vector-icons';
import { fonts } from "../style/fonts";
import { responsiveText } from "../style/fontSizes";
import { Auth, API } from 'aws-amplify';
import Toast from 'react-native-root-toast';
import alert from '../components/alert'
import Util from "../util/util.js"

const AdminHome = (props) => {

    const reports = props.route.params; //If passing in from AdminFilterHome, get reports from this

    // Defining search string state
    const [searchText, setSearchText] = useState('');


    const onScreenLoad = () => {
        //getOrganizationReports()
    }
    useEffect(() => {
        // write your code here, it's like componentWillMount
        onScreenLoad();
    }, [])

    // Function to format description length
    const formatDescription = (text) => {
        return text.replace(/[\r\n]/g, ' ').trim();
    }

    const formatUuid = (text) => {
        return text.substr(0, 3).replace(/[\r\n]/g, ' ').trim() + '...';
    }

    async function fetchProductsUUID(){
        try { 
          const user = await Auth.currentAuthenticatedUser()
          const token = user.signInUserSession.idToken.jwtToken
          const requestData = {
            headers: {
              Authorization: token
            }
          }
          try{ 
            const data = await API.get('Resty', '/items/getFromUuid/' + searchText, requestData)
            console.log("data: ", data)
            console.log("Length of items: ", data.items.length)
            console.log(data.items.toString())
            console.log(JSON.stringify(data, null, 2))
            //setText(JSON.stringify(data, null, 2));
            if(data.items.length > 0){
                props.navigation.navigate('AdminReportDetails', data.items[0])
            }
            else{
                if(Platform.OS != 'web'){
                  let toast = Toast.show('❌ No report with that UUID.', {
                      backgroundColor: "#ed2f2f",
                      duration: 5000,
                      textColor: "#fff",
                      type: 'danger',
                  });
                }
                else{
                  alert("Error", "❌ No report with that UUID.\n")
                }
            }
          }
          catch(error){
            Util.printAPIError(error)
          }
        } catch (e) {
          console.log("failed2: ",e.message);
        }
      };

    return (
        <View style={{ flex: 1, backgroundColor: '#FFF' }}>
            <Header organization={organization} type='pop' />
            <TouchableWithoutFeedback onPress={Platform.OS != 'web' ? Keyboard.dismiss : console.log("")} accessible={false} >
            <View style={{ flex: 1, paddingHorizontal: wp(6), paddingTop: hp(1.7) }}>
                {/* Title  */}
                <CustomText style={{ textAlign: 'center' }} font='medium' size={32} color={'#000'}>Previous Reports</CustomText>
                <Separator />

                {/* Report search box */}
                <View style={{ marginTop: hp(1.3), paddingHorizontal: wp(2), height: hp(6.8), borderRadius: 12, backgroundColor: 'rgba(255, 196, 81, 0.3)', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TextInput allowFontScaling={false} value={searchText} onChangeText={(text) => { setSearchText(text) }} placeholder="UUID search" placeholderTextColor={'rgba(0,0,0,0.35)'} style={{ width: '80%', height: '100%', fontFamily: fonts.POPPINS.medium, fontSize: 24, color: '#000', marginBottom: -4 }} />
                    <TouchableWithoutFeedback onPress={fetchProductsUUID}>
                        <Feather name='search' size={40} color={'#000'} />
                    </TouchableWithoutFeedback>
                </View>

                <View style={styles.orangeContainer}>
                    {/* Orange separators  */}
                    {/*<View style={{ position: 'absolute', left: wp(20.5), height: '100%', width: 3, backgroundColor: '#FFC451', marginVertical: hp(1) }} /> */}
                    {/*<View style={{ position: 'absolute', top: hp(8), height: 3, width: '100%', backgroundColor: '#FFC451', marginHorizontal: wp(14) }} />*/}

                    {/* Report mapping */}
                    <ScrollView style={{ paddingTop: hp(1), width: '100%' }}>
                        {
                          // We assume that whatever reports that need to be seen were passed in to this page as a parameter, so we do not filter them here
                            reports.map((element, index) => {
                                return (
                                    <TouchableWithoutFeedback key={element.key} onPress={() => { element.index = reports.length-index; props.navigation.navigate('AdminReportDetails', element) }}>
                                        <View key={element.key} style={{ flexDirection: 'row', alignItems: 'center', width: '100%', paddingHorizontal: wp(5) }}>
                                            {/* &gt;<Feather name={element.comments[0] === 'No comments yet' ? 'clock' : 'check-circle'} size={24} color={element.comments[0] === 'No comments yet' ? 'red' : 'green'} />*/}
                                            <CustomText font='medium' size={24}  color={element.comments[0] === 'No comments yet' ? 'red' : 'green'}>#{reports.length-index}</CustomText>
                                            <CustomText font='medium' size={24} color="black"> | </CustomText>
                                            <CustomTextElipses numberOfLines={1} ellipsizeMode="tail" style={{ flex: 1, cursor: 'pointer', marginLeft: wp(1) }} font='medium' size={24} color='#FFC451'>{formatDescription(element.content)}</CustomTextElipses>
                                        </View>
                                    </TouchableWithoutFeedback>
                                )
                            })
                        }
                    </ScrollView>

                </View>
            </View>
            </TouchableWithoutFeedback>
        </View>
    )
}

const styles = StyleSheet.create({
    orangeContainer: {
        flex: 1,
        alignItems: 'center',
        borderRadius: 12,
        backgroundColor: 'rgba(255, 196, 81, 0.3)',
        paddingVertical: 10,
        paddingHorizontal: 14,
        marginTop: hp(3)
    }
})

export default AdminHome;