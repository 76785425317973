import {React, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import AppButton from './AppButton.js'

const screenWidth = Dimensions.get('window').width


export default function UpdateUserButton({ user, postUpdateAction }) {
  const [updatingUser, setUpdatingUser] = useState(false);


  const editUser = async () => {
    /*
    * Edit user: Change password and/or role
    */

    setUpdatingUser(true)

    await postUpdateAction(user)

    setUpdatingUser(false)
  }

  return (
    <AppButton 
      title="Update"
      isBusy={updatingUser} 
      onPress={editUser}  
      buttonStyle={styles.button}
      textStyle={styles.buttonText}
    />
  );
}
const styles = StyleSheet.create({
  button: {
    cursor: 'pointer',
    marginVertical: 10,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    width: 80,
    backgroundColor: '#FFC451'
  },
  buttonText: {
    fontSize: 12,
    fontWeight: '600',
  }
});