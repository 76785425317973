import React from 'react';
import { ScrollView, View, Text, StyleSheet, Platform, TouchableOpacity, Dimensions } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";

import webStyles from '../style/webStyles';
import mobileStyles from '../style/mobileStyles';
import CustomText from '../components/CustomText';

const screenWidth = Dimensions.get('window').width


const PrivacyPolicy = (props) => {
  return (
    <ScrollView style={Platform.OS === "web" && screenWidth >= 800 ? webStyles.container : mobileStyles.container}>
      <Text style={styles.heading}>Privacy Policy</Text>
      <View style={Platform.OS === "web" && screenWidth >= 800 ? { marginLeft: 150, marginRight: 150} : {marginLeft: 15, marginRight: 15}}>
        <Text>
          This Privacy Policy ("Policy") applies to Moneta mobile app and website portal, and Moneta LLC
          ("Company") and governs data collection and usage. For the purposes of this Privacy Policy,
          unless othewise noted, all references to the Company include 3911 4th St. The Company's
          application is a software application. By using the Company application, you consent to the data
          practices desrcribed in this statement. 
        </Text>

      <Text style={styles.subheading}>Collection of your Personal Information</Text>
      <Text>
        We do not collect any personal information about you unless you voluntarily provide it to us.
        However, you may be required to provide certain personal information to us when you elect to use
        certain products or services. These may include: (a) registering for an account; (b) entering a
        sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers
        from selected third parties; (d) sending us an email message; (e) submitting your credit card or
        other payment information when ordering and purchasing products and services. To wit, we will
        use your information for, but not limited to, communicating with you in relation to services and/or
        products you have requested from us. We also may gather additional personal or non-personal
        information in the future. 
      </Text>

      <Text style={styles.subheading}>Sharing Information with Third Parties</Text>
      <Text>
        The Company does not sell, rent, or lease its customer lists to third parties.
      </Text>
      <Text>
        The Company may share data with trusted partners to help perform statistical analysis, send you
        email or postal mail, provide customer support, or arrange for deliveries. All such third parties are
        prohibited from using your personal information except to provide these services tothe Company,
        and they are required to maintain the confidentiality of your information. 
      </Text>
      <Text>
        The Company may disclose your personal information, without notice, if required to do so by law
        or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or
        comply with legal process served on the Company or the site; (b) protect and defend the rights or
        property of the Company; and/or (c) act under exigent circumstances to protect the personal
        safety of users of the Company, or the public. 
      </Text>

      <Text style={styles.subheading}>Security of your Personal Information</Text>
      <Text>
        The Company secures your personal information from unauthorized access, use, or disclosure. The
        Company uses the following methods for this purpose: 
      </Text>
      <View style={styles.bullets}>
        <Text>
          - AWS Services
        </Text>
      </View>
      <Text>
        We strive to take appropriate security measures to protect against unauthorized access to or
        alteration of your personal information. Unfortunately, no data transmission over the Internet or any
        wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect
        your personal information, you acknowledge that: (a) there are security and privacy limitations
        inherent to the Internet that are beyond our control; and (b) the security, integrity, and privacy of 
        any and all information and data exchanged between you and us through this site cannot be
        guaranteed.         
      </Text>

      <Text style={styles.subheading}>Right to Deletion</Text>
      <Text>
        Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
      </Text>
      <View style={styles.bullets}>
        <Text>
          -Delete your personal information from our records; and
        </Text>
        <Text>
          -Direct any service providers to delete your personal information from their records.
        </Text>
      </View>
      <Text>
        Please note that we may not be able to comply with requests to delete your personal information if
        it is necessary to: 
      </Text>
      <View style={styles.bullets}>
        <Text>
          -Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, and provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us;
        </Text>
        <Text>  
          -Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity;
        </Text>
        <Text>
          -Debug to identify and repair errors that impair existing intended functionality;
        </Text>
        <Text>
          -Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law;
        </Text>
        <Text>
          -Comply with the California Electronic Communications Privacy Act;
        </Text>
        <Text>
          -Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent;
        </Text>
        <Text>
          -Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us;
        </Text>
        <Text>
          -Comply with an existing legal obligation; or
        </Text>
        <Text>
          -Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.
        </Text>
      </View>

      <Text style={styles.subheading}>Children Under Thirteen</Text>
      <Text>
        The Company does not knowingly collect personally identifiable information from children under
        the age of 13. If you are under the age of 13, you must ask your parent or guardian for permission
        to use this application. 
      </Text>

      <Text style={styles.subheading}>Email Communications</Text>
      <Text>
        From time to time, the Company may contact you via email for the purpose of providing
        announcements, promotional offers, alerts, confirmations, surveys, and/or other general
        communication. In order to improve our services, we may receive a notification when you open an
        email from the Company or click on a link therein        
      </Text>

      <Text style={styles.subheading}>External Data Storage Sites</Text>
      <Text>
        We may store your data on servers provided by third-party hosting vendors with whom we have
        contracted. 
      </Text>

      <Text style={styles.subheading}>Changes to This Statement</Text>
      <Text>
        The Company reserves the right to change this Policy from time to time. For example, when there
        are changes in our services, changes in our data protection practices, or changes in the law. When
        changes to this Policy are significant, we will inform you. You may receive a notice by sending an
        email to the primary email address specified in your account, by placing a prominent notice on our
        Moneta LLC, and/or by updating any privacy information. Your continued use of the application
        and/or services available after such modifications will constitute your: (a) acknowledgment of the
        modified Policy; and (b) agreement to abide and be bound by that Policy
      </Text>
      

      <Text style={styles.subheading}>Contact Information</Text>
      <Text>
        The Company welcomes your questions or comments regarding this Policy. If you believe that the
        Company has not adhered to this Policy, please contact the Company at: 
      </Text>
      <View>
        <Text>Moneta LLC</Text>
        <Text>_________________</Text>
        <Text>Lubbock, Texas 79415</Text>
        <Text>Email Address: timothy.matis@monetatech.net</Text>
        <Text>Phone Number: 8063682281</Text>
      </View>

      <Text style={styles.date}>Effective as of January 29, 2024</Text>

      <View >
          <TouchableOpacity onPress={() => props.navigation.navigate('Login')}>
            <View style={{cursor: 'pointer', marginBottom: 20}}>
              <CustomText style={{ textDecorationLine: 'underline', marginTop: hp(3.9) }} size={24} color='#000'>Return to App</CustomText>
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </ScrollView>

  );
};

const styles = StyleSheet.create({
  heading: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
    textAlign: 'center'
  },
  subheading: {
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 5,
  },
  date: {
    marginTop: 20,
    marginBottom: 20
  },
  bullets: {
    marginLeft: 15,
    marginTop: 5,
    marginBottom: 5
  }
});

export default PrivacyPolicy;
