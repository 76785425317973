import React, { useState, useEffect } from "react"
import { Modal, Keyboard, View, StyleSheet, Image,  TouchableWithoutFeedback, TextInput, SafeAreaView, ScrollView, Platform, Button, ActivityIndicator, Pressable } from "react-native"
import { ImageViewer } from 'react-native-image-zoom-viewer'

import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Feather } from "@expo/vector-icons";

const MonetaImageGallery = (props) => {
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);

    const renderLoading = () => {
        return (<ActivityIndicator color={'white'} size={'large'} />)
    }

    // Expects values to be pass as an object with source and style.
    // This is required to work with ImageViewer.
    const renderImage = ({ source, style }) => {
        return (
            <Image
                source={{ uri: source?.uri, priority: 'high' }}
                style={style}
                resizeMode="contain"
                indicator={renderLoading}
            />
        )
    }

    const getDisplayImage = (index) => {
        if(props.images && props.images.length > index){
            return(renderImage({source: {uri: props.images[index]}, style: {flex: 1}}))
        }else{
            return(<View></View>)
        }
    }

    const decrementImage = () => {
        if(props.images && props.images.length > 0){
            setGalleryIndex((galleryIndex + props.images.length - 1) % props.images.length)
        }
    }

    const incrementImage = () => {
        if(props.images && props.images.length > 0){
            setGalleryIndex((galleryIndex + 1) % props.images?.length)
        }
    }

    const deleteImage = () => {
        const newImages = props.images.filter((value, index) => {
            return(index != galleryIndex)
          })
        props.setImages(() => newImages);

        //Special case for deleting last element
        //If deleting first or middle element, index remains the same
        //When list is empty, index should always end up being 0, so no need to worry about that either
        if(newImages.length >= galleryIndex && galleryIndex > 0){
            setGalleryIndex(newImages.length - 1);
        }
      }

    if(Platform.OS === 'web'){
        return(
            props?.images && props?.images.length > 0 && 
            <View style={{borderRadius: 12, borderColor: '#000', borderWidth: 1}}>
                <View style={{flex: 1, flexDirection: 'row', alignItems: "center"}}>
                <CustomText style={{ marginLeft: wp(3), marginTop: hp(0), flex: 1 }} font='medium' size={40} color={'#000'}>Img {galleryIndex + 1}/{props.images.length}</CustomText>
    
                {props.images.length > 1 && <View style={{flexDirection: 'row'}}>
                    <TouchableOpacity style={{  marginLeft: '100px', marginRight: '15px', height: '50px', backgroundColor: '#FFC451', flexDirection: 'row', justifyContent:"space-between", borderRadius: 5}} onPress={decrementImage}>
                        <Feather name='chevron-left' size={15} color={'#000'} style={{margin: wp(2), padding: wp(1), alignSelf: 'center'}} />
                    </TouchableOpacity>

                    <TouchableOpacity style={{  marginRight: '15px', height: '50px', backgroundColor: '#FFC451', flexDirection: 'row', justifyContent:"space-between", borderRadius: 5}} onPress={incrementImage}>
                        <Feather name='chevron-right' size={15} color={'#000'} style={{margin: wp(2), padding: wp(1), alignSelf: 'center'}} />
                    </TouchableOpacity>
                </View>
                }
        
                {props?.allowDeletion && <TouchableOpacity style={{ height: '50px', width: '50px', marginRight: '15px',backgroundColor: '#eb4129', flexDirection: 'row', justifyContent:"space-between", borderRadius: 5}} onPress={deleteImage}>
                    <Feather name='trash' size={15} color={'#000'} style={{padding: '17px', alignSelf: 'center'}} />
                </TouchableOpacity>}
                
                </View>
                
                <Pressable onPress={() => setModalVisible(true)}>
                  <View style={{margin: wp(3), height: hp(50)}}>
                    {getDisplayImage(galleryIndex)}
                  </View>
                </Pressable>
                  
                <Pressable onPress={() => setModalVisible(false)}>
                  <Modal
                    visible={modalVisible}
                    transparent={true}
                    onRequestClose={() => setModalVisible(false)}>
                    <ImageViewer
                      enablePreload={true}
                      index={galleryIndex}
                      imageUrls={props.images.map(x => ({ 'url': x }))}
                      enableSwipeDown={false}
                      renderImage={renderImage}
                      loadingRender={renderLoading}
                      saveToLocalByLongPress={false}/>    
              </Modal>
            </Pressable>
        </View>
            )
    }else{
        return(
        props?.images && props?.images.length > 0 && 
        <View style={{borderRadius: 12, borderColor: '#000', borderWidth: 1}}>
            <View style={{flex: 1, flexDirection: 'row'}}>
            <CustomText style={{ marginLeft: wp(3), marginTop: wp(5), flex: 1 }} font='medium' size={20} color={'#000'}>Img {galleryIndex + 1}/{props.images.length}</CustomText>
            {props.images.length > 1 && <View style={{flex: 1, flexDirection: 'row'}}>
                <TouchableOpacity style={{ flex: 2, margin: wp(3), width: wp(15), backgroundColor: '#FFC451', flexDirection: 'row', justifyContent:"space-between", borderRadius: 5}} onPress={decrementImage}>
                    <Feather name='chevron-left' size={15} color={'#000'} style={{margin: wp(2), padding: wp(1), marginHorizontal: wp(4)}} />
                </TouchableOpacity>

                <TouchableOpacity style={{ flex: 2, margin: wp(3), width: wp(15), backgroundColor: '#FFC451', flexDirection: 'row', justifyContent:"space-between", borderRadius: 5}} onPress={incrementImage}>
                    <Feather name='chevron-right' size={15} color={'#000'} style={{margin: wp(2), padding: wp(1), marginHorizontal: wp(5)}} />
                </TouchableOpacity>
            </View>
            }
    
            {props?.allowDeletion && <TouchableOpacity style={{ flex: 1, margin: wp(3), backgroundColor: '#eb4129', flexDirection: 'row', justifyContent:"space-between", borderRadius: 5}} onPress={deleteImage}>
                <Feather name='trash' size={15} color={'#000'} style={{margin: wp(2), padding: wp(1)}} />
            </TouchableOpacity>}
            
            </View>

            <Pressable onPress={() => setModalVisible(true)}>
              <View style={{margin: wp(3), height: hp(50)}}>
                {getDisplayImage(galleryIndex)}           
              </View>
            </Pressable>

            <Modal
              visible={modalVisible}
              transparent={true}
              onRequestClose={() => setModalVisible(false)}>
              <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
                <ImageViewer
                  enablePreload={true}
                  index={galleryIndex}
                  imageUrls={props.images.map(x => ({ 'url': x }))}
                  useNativeDriver={true}
                  enableSwipeDown={false}
                  renderImage={renderImage}
                  loadingRender={renderLoading}
                  saveToLocalByLongPress={false}/>   
                </TouchableWithoutFeedback>
            </Modal>    
        </View>
        )
    }
}

export default MonetaImageGallery;