import React from 'react';
import { Platform, StyleSheet, Text, TouchableOpacity, Dimensions, ActivityIndicator} from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";

const screenWidth = Dimensions.get('window').width


export default function AppButton({ title, onPress, buttonStyle = null, textStyle = null,  isBusy = false }) {
  return (
    <TouchableOpacity style={buttonStyle ? buttonStyle : styles.button} onPress={onPress}>
      <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={textStyle ? textStyle : styles.buttonText}>{title} {isBusy &&  <ActivityIndicator  size="small"/>}</Text>
    </TouchableOpacity>
  );
}
const styles = StyleSheet.create({
  button: {
    cursor: 'pointer',
    marginVertical: 10,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 15,
    width: Platform.OS === 'web' && screenWidth >= 1000 ? wp(40) : wp(80),
    backgroundColor: '#FFC451'
  },
  buttonText: {
    fontSize: screenWidth >= 1000 ? 28 : 18,
    fontWeight: '600',
  }
});