import React from 'react';
import { Dimensions, Text, Platform } from 'react-native';
import { fonts } from '../style/fonts';

const { width } = Dimensions.get('screen');

let scale = 1;

// Adjust fonts for viewing the web portal on smaller devices
if (width < 800 && Platform.OS === "web") {
  scale = .7
}

const getFont = (font) => {
    switch (font) {
        case 'black':
            return fonts.POPPINS.black
        case 'extraBold':
            return fonts.POPPINS.extraBold
        case 'bold':
            return fonts.POPPINS.bold
        case 'semiBold':
            return fonts.POPPINS.semiBold
        case 'medium':
            return fonts.POPPINS.medium
        case 'regular':
            return fonts.POPPINS.regular
        case 'light':
            return fonts.POPPINS.light
        case 'extraLight':
            return fonts.POPPINS.extraLight
        case 'thin':
            return fonts.POPPINS.thin
        default:
            return fonts.POPPINS.regular
    }
}

    {/* //Old is here: (probably should use this) 
        
        <Text style={[{ fontFamily: getFont(props.font, props.family), fontSize: props.size != undefined ? props.size * scale : 14 * scale, color: props.color != undefined ? props.color : '#FFFFFF' }, props.style]}>
            {props.children}
        </Text>
        */
    }
const CustomText = (props) => {
    return (
        // Now font scaling should be entirely off (?)
        <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={[{ fontFamily: getFont(props.font, props.family), fontSize: props.size != undefined ? props.size * scale : 14 * scale, color: props.color != undefined ? props.color : '#000' }, props.style]}>
            {props.children}
        </Text>
    )
}

export default CustomText;