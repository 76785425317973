import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TouchableWithoutFeedback, Platform, Keyboard, Dimensions } from 'react-native';
import { Auth } from 'aws-amplify';
import { SafeAreaView } from 'react-native-safe-area-context';
import AppTextInput from '../components/AppTextInput';
import AppButton from '../components/AppButton';
import Toast from 'react-native-root-toast';
import alert from '../components/alert'
import Util from "../util/util.js"
import Header from "../components/Header";
import webStyles from '../style/webStyles.js';
import mobileStyles from '../style/mobileStyles.js';

const screenWidth = Dimensions.get('window').width;


const ForgotPassword = (props) => {
  const organization = props.route.params;
  const [username, setUsername] = useState('');
  const [userFontSize, setUserFontSize] = useState(24);

  useEffect(() => {
    getUser()
}, [])

const getUser = async (_organization) => {
    const user = await Auth.currentAuthenticatedUser()
    setUsername(user.attributes.email);
}

  useEffect(() => {
    setUserFontSize(Util.calculateFontSize(username, 48, userFontSize))
}, [username])

  async function ForgotPassword() {
    try {
      await Auth.forgotPassword(username);
      Toast.show('✅ Verification Code Sent!', {
        backgroundColor: "#259e23",
        duration: 5000,
        //position: 50,
        textColor: "#fff",
      });
      
      props.navigation.navigate('ResetPassword')
    } catch (error) {
      console.log(
        '❌ Verification code does not match. Please enter a valid verification code.',
        error.toString()
      );
        alert("Error", "❌" + error.toString())
    }
  }
  return (
    <View style={[Platform.OS === 'web' &&  screenWidth >= 800 ? webStyles.container : mobileStyles.container, styles.safeAreaContainer]}>
      <Header organization={organization} type='pop'/>
      <TouchableWithoutFeedback onPress={Platform.OS != 'web' &&  screenWidth >= 800  ? Keyboard.dismiss : console.log("")} accessible={false} >
      <View style={styles.container}>
        <Text style={styles.title}>Confirm your email</Text>
        <AppTextInput
          value={username}
          onChangeText={text => setUsername(text)}
          leftIcon="account"
          placeholder="Enter email"
          autoCapitalize="none"
          keyboardType="email-address"
          textContentType="emailAddress"
          style={{fontSize: userFontSize, width: '100%'}}
        />
        <AppButton title="Send Password Reset Code" onPress={ForgotPassword} />
      </View>
      </TouchableWithoutFeedback>
    </View>
  );
}

const styles = StyleSheet.create({
    safeAreaContainer: {
      flex: 1,
      backgroundColor: 'white'
    },
    container: {
      flex: 1,
      alignItems: 'center'
    },
    title: {
      fontSize: 20,
      color: 'black',
      fontWeight: '500',
      marginVertical: 15
    },
    footerButtonContainer: {
      marginVertical: 15,
      justifyContent: 'center',
      alignItems: 'center'
    }, 
    forgotPasswordButtonText: {
        color: '#FFC451',
        fontSize: 18,
        fontWeight: '600'
      }
  });

  export default ForgotPassword;