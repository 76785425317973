import React, { useState, useEffect } from 'react';
import { KeyboardAvoidingView, ScrollView, View, Dimensions, Text, TouchableOpacity, StyleSheet, TouchableWithoutFeedback, Platform, Keyboard } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { Auth } from 'aws-amplify';
import { SafeAreaView } from 'react-native-safe-area-context';
import AppTextInput from '../components/AppTextInput';
import AppButton from '../components/AppButton';
import Toast from 'react-native-root-toast';
import alert from '../components/alert'
import { Feather } from '@expo/vector-icons';

import CustomText from "../components/CustomText"
import Header from "../components/Header.js"
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";

import { KeyboardAccessoryNavigation } from 'react-native-keyboard-accessory'

const screenWidth = Dimensions.get('window').width


const ResetPassword = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [authCode, setAuthCode] = useState('');

  const { initialLogin, user, authenticatedEmail } = props.route.params ? props.route.params : [false, null]

  const [errors, setErrors] = useState({})
  const [isFormValid, setIsFormValid] = useState(false)
  const [displayErrors, setDisplayErrors] = useState(false)

  const [icon, setIcon] = useState("eye-off")
  const [hidePassword, setHidePassword] = useState(true)

  const changeIcon = () => {
      icon !== "eye-off"
      ? (setIcon("eye-off"), setHidePassword(true))
      : (setIcon("eye"), setHidePassword(false))
  }

  useEffect(() => { 
    // Validate form if username, password or mode is changed
    validateForm()
  }, [username, password, authCode])

  const validateForm = () => { 
    /*
    * Form validation
    */
    let errors = {}

    // Require email
    if (!initialLogin && !username.trim()) { 
        errors.email = 'Email is required.'
    } 

    // Password must be 8 characters (if creating new user)
    if (password.trim().length < 8) {
      errors.password = 'Password must be at least 8 characters.' 
    }

    // If not the intial login, require a confirmation code
    if (!initialLogin && !authCode.trim()) {
      errors.password = 'Authorization code is required.' 
    }
    
    // Set the errors and update form validity 
    setErrors(errors)
    setIsFormValid(Object.keys(errors).length === 0) 
  }; 


  async function ResetPassword() {
    try {
      setDisplayErrors(true)
      if (!isFormValid) { return } 

      if (initialLogin) {
        await Auth.completeNewPassword(user, password)
        await Auth.signIn({ username: authenticatedEmail, password })
      } else {
        await Auth.forgotPasswordSubmit(username, authCode, password);
      }
      
      Toast.show('✅ Password Reset!', {
        backgroundColor: "#259e23",
        duration: 5000,
        textColor: "#fff",
      });

      props.navigation.navigate('Login', {autoLogin: true})
    } catch (error) {
        alert("Error", '❌ Error Changing Password\n' + error.toString())
    }
  }
  return (
    <View style={[Platform.OS === 'web' &&  screenWidth >= 800 ? webStyles.container : mobileStyles.container, styles.safeAreaContainer]}>
    <Header  type='pop'/>
    <ScrollView>
    <SafeAreaView style={styles.safeAreaContainer}>
      <TouchableWithoutFeedback onPress={Platform.OS != 'web' ? Keyboard.dismiss : console.log("")} accessible={false} >
      <View style={styles.container}>
        <Text style={styles.title}>Reset your password {initialLogin ? '' : 'using the temporary code delivered to your email'}</Text>
        {!initialLogin && <AppTextInput
          value={username}
          onChangeText={text => setUsername(text.trim())}
          leftIcon="account"
          placeholder="Enter email"
          autoCapitalize="none"
          keyboardType="email-address"
          textContentType="emailAddress"
        />}
        <CustomText style={styles.error}>{displayErrors ? errors.email: null}</CustomText>

        {!initialLogin && <AppTextInput
          value={authCode}
          onChangeText={text => setAuthCode(text)}
          leftIcon="numeric"
          placeholder="Enter verification code"
          keyboardType="numeric"
        />}
        <CustomText style={styles.error}>{displayErrors ? errors.auth_code: null}</CustomText>
        
        
        <KeyboardAvoidingView style={styles.textboxContainer}>
          <AppTextInput
            value={password}
            onChangeText={text => setPassword(text)}
            leftIcon="lock"
            placeholder="Enter password"
            autoCapitalize="none"
            autoCorrect={false}
            secureTextEntry={hidePassword} 
            textContentType="password"
          />
          <View style={styles.textboxIconContainerEye}>
            <Feather name={icon} size={24} onPress={() => changeIcon()} />
          </View>
        </KeyboardAvoidingView>
        <CustomText style={styles.error}>{displayErrors ? errors.password: null}</CustomText>
        <AppButton title="Confirm New Password" onPress={ResetPassword} />
        <TouchableOpacity onPress={() => props.navigation.navigate('Login')}>
            <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={styles.forgotPasswordButtonText}>
              Back to Login
            </Text>
          </TouchableOpacity>
      </View>
      </TouchableWithoutFeedback>
    </SafeAreaView>
    </ScrollView>
    <KeyboardAccessoryNavigation androidAdjustResize avoidKeyboard />
    </View>
  );
}

const styles = StyleSheet.create({
    safeAreaContainer: {
      flex: 1,
      backgroundColor: 'white',
      width: Platform.OS === 'web' && screenWidth >= 800 ? "70%" : "100%",
      alignSelf: 'center'
    },
    container: {
      flex: 1,
      alignItems: 'center'
    },
    title: {
      fontSize: 20,
      fontWeight: '500',
      marginVertical: 15
    },
    error: {
      color: "red",
      fontWeight: "bold",
      textAlign: "center"
    },
    forgotPasswordButtonText: {
      color: '#FFC451',
      fontSize: 18,
      fontWeight: '600'
    },
    textboxContainer: {
      flexDirection: 'row',
      borderRadius: 12,
      width: Platform.OS === 'web' && screenWidth >= 800 ? wp(40) : wp(80),
      marginTop: hp(3.8),
  },
  textboxIconContainerEye: {
      backgroundColor: '#FFC451',
      borderRadius: 12,
      width: Platform.OS === 'web' && screenWidth >= 800 ? wp(5) : wp(13),
      height: 62.57,
      marginTop: hp(1.2),
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      right: 0
  },
  });

  export default ResetPassword;