import { React, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import AppTextInput from './AppTextInput.js';
import { fonts } from "../style/fonts";

const screenWidth = Dimensions.get('window').width


export default function ResetUserPassword({ user, postUpdateAction }) {
  const [password, setPassword] = useState('')

  const updatePassword = async (password) => {
    /*
    * Edit user: Change password and/or role
    */
    
      setPassword(password)
      postUpdateAction(user, password)
  }

  return (
    <AppTextInput
      value={password}
      onChangeText={text => {updatePassword(text)}}
      leftIcon="lock"
      width='10%'
      placeholder="Temp password"
      autoCapitalize="none"
      autoCorrect={false}
      secureTextEntry
      textContentType="password"
      autoComplete="new-password" 
      boxStyle={styles.container}
      textStyle={styles.input}
    />);
}
const styles = StyleSheet.create({
  button: {
    cursor: 'pointer',
    marginVertical: 10,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    width: 80,
    backgroundColor: '#FFC451'
  },
  buttonText: {
    fontSize: 12,
    fontWeight: '600',
  },
  container: {
    backgroundColor: '#f9f9f9',
    borderRadius: 12,
    flexDirection: 'row',
    padding: 5,
    marginVertical: 10,
    width: 175,
    marginRight: 15
  },
  input: {
    width: '100%',
    fontSize: 12,
    color: '#101010',
    fontFamily: fonts.POPPINS.medium,
  }
});