import React from 'react';
import { Image, StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import PropTypes from 'prop-types';
import AppStyles from '../AppStyles';
import LegendItem from './LegendItem';
import { Wrap, Box } from 'react-native-flex-layout';

export default class LegendSection extends React.Component {

    getLegendList = (legendItems) => {
        var output = []
        legendItems.map((legendItem,index) => {
            output.push(<LegendItem key={index} title={legendItem.title} color = {legendItem.color}/>)
        })
        return(output)
    }

    render() {
    const {legendItems} = this.props;
    return (
        <View
            style={styles.container}>
            <Wrap shouldWrapChildren="true" spacing={5}>
                {this.getLegendList(legendItems)}
            </Wrap>       
        </View>
    );
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: "white",

    }
});