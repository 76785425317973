const { Auth, API } = require('aws-amplify');


const sendEmailNotification = async (details, message, type, organization, userId, images=[], isUrgent=false, ) => {
  const notifications = {
    userSubmitted: [`${isUrgent ? 'URGENT' : ''} Notification: Moneta Near-Miss Report Submitted - `, 'admins', true],
    bugSubmitted: ['Notification: Moneta Bug Report Submitted - ', 'support@monetatech.net', true],
    passwordReset: ['Moneta: Your temporary password - ', userId, false],
  };

  if (!notifications.hasOwnProperty(type)) {
    throw new Error('Invalid Notification Type');
  }

  // Prepare attachments
  const attachments = images.map((imagePath, index) => ({
    path: imagePath,
    cid: `unique@screenshot-${index}`,
  }));

  // Construct subject
  let subject = notifications[type][0];
  if (notifications[type][2]) {
    subject += `${userId} - `;
  }

  // Prepare email data
  const emailData = {
    details,
    message,
    subject,
    organization,
    sendTo: notifications[type][1],
    attachments,
  };

  return emailData;
  }

  module.exports = {
    sendEmailNotification,
  };