import {StyleSheet, Platform, Dimensions} from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";


const screenWidth = Dimensions.get('window').width


const webStyles = StyleSheet.create({
    container: {
        flex:1,
        width: '70%',
        minWidth: screenWidth < 800 ? screenWidth : 800,
        alignSelf: 'center',
        backgroundColor: "#FFF"
    },
    orangeContainer: {
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        height: hp(10),
        borderColor: '#FFC451',
        borderWidth: 5,
        borderRadius: 12,
        width: 600,
        maxWidth: wp(85),
        alignSelf: "center",
        backgroundColor: 'rgba(255, 196, 81, .7)',
        marginBottom: hp(5)
    },
    backgroundImage: {
        position: 'absolute',
        bottom: 0,
        width: Platform.OS === 'web' && screenWidth >= 800 ? 550 : 300,
        height: Platform.OS === 'web' && screenWidth >= 800 ? 370 : 200,
        opacity: .5,
        zIndex: -1,
        right: 0

    },
    backgroundImageTop: {
        position: 'absolute',
        top: 0,
        width: Platform.OS === 'web' && screenWidth >= 800 ? 400 : 320,
        height: Platform.OS === 'web' && screenWidth >= 800 ? 280 : 220,
        opacity: .5,
        zIndex: -1,
        left: 0
    },
    reportContainer: {
        height: hp(10), 
        borderTopColor: 'rgba(255, 196, 81, .8)',
        borderBottomColor: 'rgba(255, 196, 81, .8)',
        borderTopWidth: .5,
        borderBottomWidth: .5,
        flexDirection: 'row', 
        alignItems: 'center', 
        width: '100%',
        paddingHorizontal: wp(1),
      },
      report: {
        flex: 1, 
        marginLeft: 1,
        color: 'black',
      },
      reportInfo: {
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row'
      },
      reportInfoItem: {
        color: 'black',
      },
      reportInfoContainer: {
        flex: 2,
        padding: 5
      },
      reportCheck: {
        minWidth: 25, 
        paddingTop: Platform.OS === 'ios' ? 32 : Platform.OS === 'web' ? 20 : 35
      },
      tagContainer: {
        backgroundColor: "white",
        flex: 1,
        paddingVertical: hp(1),
        paddingHorizontal: wp(1),
      },
      urgentTag:{
        marginHorizontal: hp(0),
        backgroundColor: 'red',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: 100,
        minHeight: 30,
      },
      closedTag:{
        marginHorizontal: hp(0),
        backgroundColor: 'green',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: 100,
        minHeight: 30,
      },
      TagText:{
        marginHorizontal: wp(1),
        alignSelf: "center"
      },
      commentsContainer: {
        width: screenWidth >= 800 ? '95%' : '100%', 
        alignSelf: 'center',
      },
      commentContainer: {
        marginTop: 20, 
      },
      secondaryCommentContainer: {
        textAlign: 'end'
      },
      primaryCommentUserName: {
        fontWeight: 'bold'
      },
      secondaryCommentUserName: {
        fontWeight: 'bold',
        textAlign: 'end'
      },
      primaryCommentDateTime: {
        fontWeight: 'bold',
        color: 'rgba(0, 0, 0, .4)'
      },
      secondaryCommentDateTime: {
        fontWeight: 'bold',
        textAlign: 'end',
        color: 'rgba(0, 0, 0, .4)'
      },
      primaryComment: {
        backgroundColor: 'lightblue',
        maxWidth: 500
      },
      secondaryComment: {
        backgroundColor: 'lightgreen', 
        alignSelf: 'end'
      },
      addComment: {
        width: '100%', 
        alignSelf: 'center'
      }
})

export default webStyles;