import React, { useState, useEffect } from "react"
import { Modal, View, Text, StyleSheet, ScrollView, TouchableOpacity, Dimensions, useWindowDimensions, Platform, Pressable } from 'react-native';
import { DataTable } from 'react-native-paper';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";
import { Wrap, Box } from 'react-native-flex-layout';
import { Auth, API } from 'aws-amplify';
import AppStyles from "../AppStyles";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LegendSection from "../components/LegendSection";
import Util from "../util/util.js"
const { DateTime } = require("luxon");

import AsyncStorage from '@react-native-async-storage/async-storage';

import {LineChart} from "react-native-chart-kit";
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";
import { Feather } from "@expo/vector-icons";


const WINDOW_WIDTH = Dimensions.get("window").width;


//Color pallete used for categorical data
const categorical_palette = ["rgb(88,181,225)", "rgb(181,29,73)", "rgb(27,225,159)", "rgb(25,2,57)", "rgb(201,240,127)", "rgb(104,45,189)", "rgb(150,252,16)", "rgb(243,67,217)", "rgb(48,151,46)", "rgb(249,150,241)", "rgb(27,81,29)", "rgb(254,112,125)", "rgb(28,88,114)", "rgb(216,175,176)", "rgb(5,19,17)", "rgb(183,255,240)", "rgb(78,12,8)", "rgb(141,166,103)", "rgb(159,4,252)", "rgb(251,189,19)", "rgb(50,86,194)", "rgb(234,107,18)", "rgb(115,83,119)", "rgb(171,123,5)", "rgb(160,163,253)", "rgb(85,69,10)", "rgb(255,42,13)"]

const AnalyticsScreen = (props) => {
  const { organization, admin } = props.route.params;
  const [allReports, setAllReportsState] = useState([])

  const [submittedReports, setSubmittedReports] = useState(0);
  const [responseTime, setResponseTime] = useState('---');

  const [validKPIs, setValidKPIs] = useState([])

  const defaultActiveFilter = -1
  const startTime = DateTime.now()
  const[activeFilter, setActiveFilter] = useState(() => defaultActiveFilter);

  const[KPIColors, setKPIColors] = useState([])
  const [modalVisible, setModalVisible] = useState(false);
  const [sortedKPIDatasetDetails, setSortedKPIDatasetDetails] = useState([])

  const timeOptions = [
    "Past 24 hours",
    "Past 7 Days",
    "Past Month",
    "Past 6 months",
    "Past Year",
    "Past 3 Years",
    "All Time"
  ]

  const printElapsedTime = (text = "") => {
    if(text != ""){console.log(text)}
    console.log(DateTime.now() - startTime)
  }

  const updateValidKPIs = (kpis) => {
    const kpiSet = new Set(kpis)
    allReports.forEach(report => {kpiSet.add(report.KPI_AI)})
    setValidKPIs(Array.from(kpiSet))
    setKPIColors(Array.from(getColors(kpiSet.size)))
  }

  const getColor = () => {
    return `rgb(${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)}, ${Math.floor(Math.random() * 256)})`
  }

  const getColors = (totalColors) => {
    const colors = new Set();
    
    while (colors.size < totalColors) {
      colors.add(getColor())
    }

    return colors;
  }

  //Get valid KPIs
  const getValidKPIs = async (_organization) => {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    const requestData = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        organization: _organization,
        value_type: "KPI"
      }
    }
    try{ 
      const data = await API.get('Resty','/items/organization_configuration/value_type', requestData)
      return(data.items) 
    }
    catch(error){
      Util.printAPIError(error)
    }
    return([])
  }

  const [allGraphStates, setAllGraphStates] = useState({
    "AF": {labels: ["NAN"],
    datasets:[
      {
        data: [0],
        color: () => `rgb(212,212,212)`,
      }
    ]},
    "RD": {labels: ["NAN"],
    datasets:[
      {
        data: [0],
        color: () => `rgb(212,212,212)`,
      }
    ]},
    "AR": {labels: ["NAN"],
    datasets:[
      {
        data: [0],
        color: () => `rgb(212,212,212)`,
      }
    ]},
    "KPI": {labels: ["NAN"],
    datasets:[
      {
        data: [0],
        color: () => `rgb(212,212,212)`,
      }
    ]}
  })

// Sum all values in an array
const sumArray = arr => {
  if (!Array.isArray(arr)) return 0;
  return arr.reduce((acc, val) => acc + val, 0);
};

// Consider breaking up this section into separate functions
// for calculating total range and number of segments
const isIterable = (obj) => {
  return(obj == null ? false : typeof obj[Symbol.iterator] === 'function')
}

const getTotalRange = (data) => {
  const max = isIterable(data) ? Math.max(...data) : 1
  const min = isIterable(data) ? Math.min(...data) : 1
  return max - min
}

const getNumberSegmentsMany = (data) => {
  const ranges = data.map((value, index) => {
    return getTotalRange(value["data"])
  })

  const maxRange = Math.max(...ranges)
  return Math.min(5, maxRange)
}

const [allDerivedGraphInfo, setAllDerivedGraphInfo] = useState({
  "AF": {segments: 5},
  "RD": {segments: 5},
  "AR": {segments: 5},
  "KPI": {segments: 5}
})

useEffect(() => {
  let output = {}

  Object.keys(allGraphStates).forEach((value, index) => {
    let suboutput = {}
    suboutput.segments = getNumberSegmentsMany(allGraphStates[value]?.datasets)
    output[value] = suboutput
  })
  setAllDerivedGraphInfo(output)
}, 
//Passing an empty array as the dependency prevents infinite loop
[allGraphStates]);

//Computation of Graph parameters is done in updateAnalytics for a variety of situations
//SetGraphStates applies this data to the graphs the same way for every scenario
const setGraphStates = (dateLabels, timeSeries) => {
  var KPIDatasets = []

  validKPIs.forEach((KPI,i)=>{
    if(KPI in timeSeries){
      KPIDatasets.push({
        data: timeSeries[KPI],
        color: () => KPIColors[i]
      })
    }
  })

  // Create an array of objects with the KPI dataset, associated labels, and sum of values.
  // This will be used to display detailed data in a table so it's easier to track KPIs.
  // It is sorted by highest sum so the largest items show at the top of the table.
  const KPIDatasetDetails = KPIDatasets.map((dataset, index) => ({
    dataset: dataset,
    label: validKPIs[index],
    sum: sumArray(dataset["data"]),
  }));
  const result = KPIDatasetDetails.slice().sort((a, b) => b.sum - a.sum)
  setSortedKPIDatasetDetails(result)

  setAllGraphStates({
    "AF":
      {
        labels: dateLabels,
        datasets:[
          {
            data: timeSeries["PF"],
            color: () => `rgb(212,212,212)`,
          },
          {
            data: timeSeries["RS"],
            color: () => `rgb(235,78,0)`,
          },
          {
            data: timeSeries["SO"],
            color: () => `rgb(0,110,235)`,
          },
        ]
      },
    "RD": {
      labels: dateLabels,
      datasets:[
        {
          data: timeSeries["CR"],
          color: () => `rgb(212,212,212)`,
        },
        {
          data: timeSeries["DE"],
          color: () => `rgb(235,78,0)`,
        },
      ]
    },
    "AR": {
      labels: dateLabels,
      datasets:[
        {
          data: timeSeries["ALL"],
          color: () => `rgb(0,110,235)`,
        }
      ]
    },
    "KPI": {
      labels: dateLabels,
      datasets: KPIDatasets
    }
  })
}

function generateReportAnalytics(data, bucketStartTimes, timeSeries, timeAmount, dateLabels) {
  let timeElapsed = 0
  let totalReportsWithTime = 0
  data.forEach(report => 
    {
      if (report.hasOwnProperty("timeElapsedUntilResponse")) {
        timeElapsed += report.timeElapsedUntilResponse
        totalReportsWithTime += 1
      }
      populateTimeSeries(bucketStartTimes, DateTime.fromMillis(report.timestamp), report, timeSeries, timeAmount)
    });

  setGraphStates(dateLabels,timeSeries)
  setSubmittedReports(data.length)
  setResponseTime(totalReportsWithTime ? Number((timeElapsed/(1000 * 60 * 60)/totalReportsWithTime).toFixed(2)) : '---')
}

function populateTimeSeries(bucketStartTimes, reportDate, report, timeSeries, timeAmount) {
  if (report == null) { return; }

  let found = false
  bucketStartTimes.reverse().forEach((bucketStart, index) =>{
    let isBigger = reportDate.toMillis() > bucketStart.toMillis()
    if(!found && isBigger){

      // Override AI if admin corrected the AI
      if(report.HROs && report.HROs.length > 0){
        report.HROs.forEach((HRO,index) => {
          if(HRO in timeSeries){
            timeSeries[HRO][timeAmount-index-1] += 1
          }
        })
      } else if(report.HRO_AI && report.HRO_AI in timeSeries) {
        timeSeries[report.HRO_AI][timeAmount-index-1] += 1
      }
      if(report.KPIs && report.KPIs.length > 0){
        report.KPIs.forEach((KPI,i) => {
          if(KPI in timeSeries){
            timeSeries[KPI][timeAmount-index-1] += 1
          }
        })
      }
      if(report.KPI_AI && !report.AIOverride){
        if(report.KPI in timeSeries) {
          timeSeries[report.KPI_AI][timeAmount-index-1] += 1
        } else {
          timeSeries[report.KPI_AI] = Array.from({length: timeAmount}, (e,i) => 0)
          timeSeries[report.KPI_AI][timeAmount-index-1] = 1
        }
          
      }
      timeSeries["ALL"][timeAmount-index-1] += 1;
      found = true;
    }
  }) 
}

function isQueryModeValid(queryMode) {
  /*
   * Checks if queryMode is valid.
  */
  return ["hour", "day", "week", "month", "year"].includes(queryMode);
}

function isPositiveInteger(value) {
  /*
   * Checks if value is a positve integer
   * This function is generic and could be moved
   * to a utils file.
  */
  return Number.isInteger(value) && value > 0;
}
function isTimeStrideValid(timeStride) {
  /*
   * Checks if timeStride is valid.
  */
  return isPositiveInteger(timeStride);
}

function isTimeAmountValid(timeAmount) {
  /*
   * Checks if timeAmount is valid.
  */
  return isPositiveInteger(timeAmount);
}

function isQueryValid(queryMode, timeAmount, timeStride) {
  /*
   * Checks if entire query is valid.
  */

  return (
    isQueryModeValid(queryMode) &&
    isTimeAmountValid(timeAmount) &&
    isTimeStrideValid(timeStride)
  );
}

function filterReports(startTime, endTime) {
  /*
   * Filter reports from startTime to endTime.
  */
  let filteredReports = [];

  for (let report of allReports) {
    if (
      report.timestamp >= startTime.toMillis() &&
      report.timestamp <= endTime.toMillis()
    ) {
      filteredReports.push(report);
    }
  }
  return filteredReports;
}

function getTimeSeries(buckets, categories) {
  /*
   * Build buckets for a time series given a number of buckets and categories
  */
  const timeSeries = {};
  timeSeries["ALL"] = Array.from({ length: buckets }, (e, i) => 0);
  for (let category of categories) {
    timeSeries[category] = Array.from({ length: buckets }, (e, i) => 0);
  }
  return timeSeries;
}

function convertQueryModeToDateTimeParam(queryMode) {
  /*
   * Converts queryMode to a valid DateTime param.
  */
  return queryMode == "month" ? queryMode : queryMode + "s";
}

function getStartAndEndTimes(queryMode, timeUnits) {
  /*
   * Gets start and end times based on queryMode by specified timeUnits
  */

  // Set endTime to the end of the time period indicated by queryMode relative to the current time. 
  // If queryMode is "hour", just use the current time.
  const timeNow = DateTime.now();
  const endTime = queryMode != "hour" ? timeNow.endOf(queryMode) : timeNow;

  // Calculate backwards by time unit from endTime to get the startTime based total time units. Time unit is indicated by queryMode.
  const startTime = endTime.minus({
    [convertQueryModeToDateTimeParam(queryMode)]: timeUnits,
  });

  return [startTime, endTime];
}

function getBucketStartTimes(queryMode, endTime, timeStride, timeAmount) {
  /*
   * Calculate bucket start times for a time period based on queryMode.
  */

  // Each bucket start time counts back by time unit based on queryMode, timeAmount and timeStride. 
  // If queryMode is hour just use the current time, otherwise, set to the endOf queryMode.
  const bucketStartTimes = [];
  for (let i = timeAmount; i >= 1; i--) {
    let bucketStartTime = endTime.minus({
      [convertQueryModeToDateTimeParam(queryMode)]: i * timeStride,
    });
    if (queryMode != "hour") {
      bucketStartTime = bucketStartTime.endOf(queryMode);
    }
    bucketStartTimes.push(bucketStartTime);
  }
  
  return bucketStartTimes;
}

function getDateLabels(queryMode, bucketStartTimes) {
  /*
   * Get x-axis date labels for a time period based on queryMode.
  */

  // Format to use for each queryMode
  const format = {
    hour: "HH:mm",
    day: "MM/dd",
    week: "MM/dd",
    month: "MM/yy",
    year: "yyyy",
  };

  // Hour to add per each queryMode.
  const plus_hours = {
    hour: 0,
    day: 12,
    week: 1,
    month: 1,
    year: 1,
  };

  // Create x-axis labels for each bucket.
  const dateLabels = [];
  bucketStartTimes.forEach((st) => {
    let label = st;
    if (plus_hours[queryMode] > 0) {
      label = label.plus({ hours: plus_hours[queryMode] });
    }
    dateLabels.push(label.startOf(queryMode).toFormat(format[queryMode]));
  });

  return dateLabels;
}

//This function should be triggered both upon entering this screen, and refreshing or changing settings
const updateAnalytics = async (queryMode, timeAmount = 0, timeStride = 1) => {
  /*
   * Update analytics for all reports in the specified time frame.
   * queryMode: "hour", "day", "week", "month", "year"
   * timeAmount: number of time units
   * timeStride: number of time units lumped together on x-axis
   */

  // Only calculate analytics if query options are valid.
  if (!isQueryValid(queryMode, timeAmount, timeStride)) {
    logger.error(
      `Invalid query options: MODE: ${queryMode} BUCKETS: ${timeAmount} INCREMENT: ${timeStride}`,
    );
    return;
  }

  // Build buckets to hold counts for each category for each unit indicated by timeAmount
  // validKPIs is calculated upon startup in a state variable
  const validHROs = ["PF","RS","SO","CR","DE","NA"]
  const timeSeries = getTimeSeries(timeAmount, validHROs.concat(validKPIs));

  // Get start time and end time for the time period indicated by queryMode. Number of time units
  // is timeAmount * timeStride.
  const [startTime, endTime] = getStartAndEndTimes(
    queryMode,
    timeAmount * timeStride,
  );

  // Filter reports from startTime to endTime.
  // No need to query database each time.
  const data = filterReports(startTime, endTime);

  // Get bucket start times for the query mode.
  const bucketStartTimes = getBucketStartTimes(
    queryMode,
    endTime,
    timeStride,
    timeAmount,
  );

  // Create labels based on queryMode for all bucket start times
  const dateLabels = getDateLabels(queryMode, bucketStartTimes);

  // Get the analytics.
  generateReportAnalytics(
    data,
    bucketStartTimes,
    timeSeries,
    timeAmount,
    dateLabels,
  );
}

//useEffect acts like componentDidMount() for now
useEffect(() => {

  const generateReports = async (_startTime, _endTime, _organization) =>{
    let routing = JSON.parse(await AsyncStorage.getItem("routing"))
    let routingData = Object.values(routing)

    try { 
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const requestData = {
        headers: {
          Authorization: token
        },
        queryStringParameters: {
          startTime: _startTime,
          endTime: _endTime,
          organization: _organization,
          organizations: routingData.length ? organization + "," + routingData.toString() : organization,
          statsOnly: true
        }
      }
      try{ 
        const data = await API.get('Resty','/items/queryTimeRange', requestData)
        setAllReportsState(() => data["items"])
      }
      catch(error){
        Util.printAPIError(error)
      }
    } catch (e) {
      console.log("failed2: ",e.message);
    }
    return({
      items: [{
        user: "",
        timestamp: 0,
        comments: ["",""],
        content: "No reports yet, from AdminFilterHome",
        organization: "",
        uuid: "",
        reporter: "",
        HROClassification: "",
      }]
    })
  };

  //get ALL reports for now
  generateReports(0,DateTime.now().toMillis(),organization)
  setActiveFilter(3)
}, 
//Passing an empty array as the dependency prevents infinite loop
[]);

useEffect(() => {
  if(allReports.length > 0 && validKPIs.length == 0){
    //prefer using pre-computed validKPIs to speed things up
    if(props.route.params && props.route.params.validKPIs != null && props.route.params.validKPIs.length){
      const vkpis = props.route.params.validKPIs.map((value, index) => {
      return value["value"] //Only keep the content of the KPI and not metadata
    })
    updateValidKPIs(vkpis)
    }else{
      (async () => {
        const vkpis = (await getValidKPIs(organization)).map((value, index) => {
          return value["value"] //Only keep the content of the KPI and not metadata
        })
        updateValidKPIs(vkpis)})
    }
  }
}, [allReports])

useEffect(() => {
  if(allReports.length > 0 && validKPIs.length){
  //Call async function from anonymous async function to avoid making useEffect() async
  switch(activeFilter){
    case 0:
      (async () => {
        await updateAnalytics("hour", 12, 2); //Split 24 hours into 12 2-hour chunks
      })();
      break;
    case 1:
      (async () => {
        await updateAnalytics("day", 7); //1 Week = 7 days
      })();
      break;
    case 2:
      (async () => {
        await updateAnalytics("week", 4); //1 Month = 4 weeks
      })();
      break;
    case 3:
      (async () => {
        await updateAnalytics("month", 6); //6 Months = 6 months
      })();
      break;
    case 4:
      (async () => {
        await updateAnalytics("month", 12); //12 months = 12 months
      })();
      break;
    case 5:
    case 6:
      (async () => {
        await updateAnalytics("month", 12, 3); //3 years = 12 months * 3
      })();
      break;
  
  }
  }
}, 
//Passing an empty array as the dependency prevents infinite loop
[activeFilter, allReports, validKPIs]);

  if(Platform.OS === 'web' && WINDOW_WIDTH >= 800){
    return (
      <View style={webStyles.container}>
        <Header organization={organization} admin={admin} type='pop' web={true}/>
        <View style={styles.container}>
          <View style={styles.chip_container}>
            <Wrap shouldWrapChildren="true" spacing={5}>
                {/* Chip Chips  */}
                {
                    timeOptions.length == 0 ? null : timeOptions.map((element, index) =>{
                        return(
                            <TouchableOpacity key={index} onPress={() => setActiveFilter(index)}>
                                <Box style = {activeFilter == index ? styles.ChipSelected : styles.Chip}>
                                    <CustomText style = { styles.ChipText} font='medium' size={13} color={'#000'}>{element}</CustomText>
                                </Box>
                            </TouchableOpacity>
                        )
                    })
                }
            </Wrap>
          </View>
  
          <View
            style={{
              flexDirection: 'row',
              height: 150,
              padding: 20,
            }}>
            <View style={styles.numerical_metric_container_left}>
              <Text style={styles.small_title}>Submitted Reports</Text>
              <Text style={styles.numerical_headline}>{submittedReports}</Text>
            </View>
            <View style={styles.numerical_metric_container_right}>
              <Text style={styles.small_title}>Avg. Response Time (Hours)</Text>
              <Text style={styles.numerical_headline}>{responseTime}</Text>
            </View>
          </View>
          <View style={styles.seperator} />
          <Text style={styles.title}>All Reports</Text>
          <LineChart data={allGraphStates["AR"]}
            verticalLabelRotation={0}
            horizontalLabelRotation={0}
            withShadow={false}
            width={useWindowDimensions().width * .7} // from react-native
            height={650}
            segments={allDerivedGraphInfo["AR"].segments}
            chartConfig={LineChartConfig}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }}
            
          />
          <LegendSection legendItems={[
            {title: "Reports", color: `rgb(0,110,235)`},
          ]}/>
          <View style={styles.seperator} />
          <Text style={styles.title}>Anticipation Of Failure</Text>
          <LineChart data={allGraphStates["AF"]}
            verticalLabelRotation={0}
            horizontalLabelRotation={0}
            withShadow={false}
            width={useWindowDimensions().width * .7} // from react-native
            height={650}
            segments={allDerivedGraphInfo["AF"].segments}
            chartConfig={LineChartConfig}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }}
            
          />
          {/*<LineChart data={AFState} verticalLabelRotation={110} width={WINDOW_WIDTH} height={220} fromZero={true} withShadow = {false} config={{ renderHorizontalLines: { count: 10 } }} gridMin={0} chartConfig={AppStyles.chartConfigMultiLine} />*/}
          <LegendSection legendItems={[
            {title: "Preoccupation with Failure (PF)", color: `rgb(212,212,212)`},
            {title: "Reluctance to Simplify (RS)", color: `rgb(235,78,0)`},
            {title: "Sensitivity to Operations (SO)", color: `rgb(0,110,235)`},
          ]}/>
          <View style={styles.seperator} />
          <Text style={styles.title}>Response to Deviations</Text>
          <LineChart data={allGraphStates["RD"]}
            verticalLabelRotation={0}
            horizontalLabelRotation={0}
            withShadow={false}
            width={useWindowDimensions().width * .7} // from react-native
            height={650}
            segments={allDerivedGraphInfo["RD"].segments}
            chartConfig={LineChartConfig}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }}
            
          />
          {/*<LineChart data={RDState} verticalLabelRotation={110} width={WINDOW_WIDTH} height={220} fromZero={true} withShadow = {false} config={{ renderHorizontalLines: { count: 10 } }} gridMin={0} chartConfig={AppStyles.chartConfigMultiLine}/>*/}
          <LegendSection legendItems={[
            {title: "Commitment to Resilience (CR)", color: `rgb(212,212,212)`},
            {title: "Deference to Expertise (DE)", color: `rgb(235,78,0)`},
          ]}/>
          <View style={styles.seperator} />
          <Pressable onPress={() => setModalVisible(true)}>
          <Text style={styles.title}>Hazard Classifications</Text>
          <LineChart data={allGraphStates["KPI"]}
            verticalLabelRotation={0}
            horizontalLabelRotation={0}
            withShadow={false}
            width={useWindowDimensions().width * .7} // from react-native
            height={650}
            segments={allDerivedGraphInfo["KPI"].segments}
            chartConfig={LineChartConfig}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }}/>

          {/*<LineChart data={KPIState} verticalLabelRotation={110} width={WINDOW_WIDTH} height={220} fromZero={true} withShadow = {false} config={{ renderHorizontalLines: { count: 10 } }} gridMin={0} chartConfig={AppStyles.chartConfigMultiLine}/>*/}
          <LegendSection legendItems={
            validKPIs.map((value, index) => {
              return({title: value, color: KPIColors[index]})
            })
          }/>
          </Pressable>
        </View>
        <Modal
          style={{flex: 1, justifyContent: 'center'}}
          visible={modalVisible}
          transparent={false}
          onRequestClose={() => setModalVisible(false)}>
            <Pressable onPress={() => setModalVisible(false)}>
              <Feather name="x" size={24} style={{ alignSelf: 'flex-start', marginLeft: 15, marginTop: 15}}></Feather>
            </Pressable>

          <ScrollView>
            <DataTable> 
              <DataTable.Header> 
                <DataTable.Title ></DataTable.Title>
                {allGraphStates["KPI"]["labels"] && allGraphStates["KPI"]["labels"].map((label, index) => {
                  return(<DataTable.Title key={index} numeric>{ label }</DataTable.Title>) 
                })}       
              </DataTable.Header> 
              {sortedKPIDatasetDetails.length && sortedKPIDatasetDetails.map((obj, index) => {
                return (
                  <DataTable.Row key={index}> 
                    <DataTable.Title >{obj.label}</DataTable.Title>
                    {obj.dataset["data"].map((value, i) => (
                      <DataTable.Cell numeric key={i}>{value}</DataTable.Cell>
                    ))}
                  </DataTable.Row>);
              })}
            </DataTable> 
          </ScrollView>
        </Modal>
        <Footer admin={admin}/>
      </View>
    );
  }else{
    return (
      <View style={mobileStyles.container}>
        <Header organization={organization} admin={admin} type='pop'/>
        <ScrollView style={styles.container}>
          <View style={styles.chip_container}>
            <Wrap shouldWrapChildren="true" spacing={5}>
                {/* Chip Chips  */}
                {
                    timeOptions.length == 0 ? null : timeOptions.map((element, index) =>{
                        return(
                            <TouchableOpacity key={index} onPress={() => setActiveFilter(index)}>
                                <Box style = {activeFilter == index ? styles.ChipSelected : styles.Chip}>
                                    <CustomText style = { styles.ChipText} font='medium' size={13} color={'#000'}>{element}</CustomText>
                                </Box>
                            </TouchableOpacity>
                        )
                    })
                }
            </Wrap>
          </View>
  
          <View
            style={{
              flexDirection: 'row',
              height: 150,
              padding: 20,
            }}>
            <View style={styles.numerical_metric_container_left}>
              <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={[styles.small_title, {marginBottom: 18}]}>Submitted Reports</Text>
              <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={styles.numerical_headline}>{submittedReports}</Text>
            </View>
            <View style={styles.numerical_metric_container_right}>
              <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={styles.small_title}>Avg. Response Time (Hours)</Text>
              <Text adjustsFontSizeToFit={true} allowFontScaling={false} style={styles.numerical_headline}>{responseTime}</Text>
            </View>
          </View>
          <View style={styles.seperator} />
          <Text style={styles.title}>All Reports</Text>
          <LineChart data={allGraphStates["AR"]}
            verticalLabelRotation={-90}
            horizontalLabelRotation={0}
            withShadow={false}
            width={Dimensions.get('window').width} // from react-native
            height={220}
            segments={allDerivedGraphInfo["AR"].segments}
            chartConfig={LineChartConfig}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }}
            
          />
          <LegendSection legendItems={[
            {title: "Reports", color: `rgb(0,110,235)`},
          ]}/>
          <View style={styles.seperator} />
          <Text style={styles.title}>Anticipation Of Failure</Text>
          <LineChart data={allGraphStates["AF"]}
            verticalLabelRotation={-90}
            horizontalLabelRotation={0}
            withShadow={false}
            width={Dimensions.get('window').width} // from react-native
            height={220}
            segments={allDerivedGraphInfo["AF"].segments}
            chartConfig={LineChartConfig}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }}
            
          />
          {/*<LineChart data={AFState} verticalLabelRotation={110} width={WINDOW_WIDTH} height={220} fromZero={true} withShadow = {false} config={{ renderHorizontalLines: { count: 10 } }} gridMin={0} chartConfig={AppStyles.chartConfigMultiLine} />*/}
          <LegendSection legendItems={[
            {title: "Preoccupation with Failure (PF)", color: `rgb(212,212,212)`},
            {title: "Reluctance to Simplify (RS)", color: `rgb(235,78,0)`},
            {title: "Sensitivity to Operations (SO)", color: `rgb(0,110,235)`},
          ]}/>
          <View style={styles.seperator} />
          <Text style={styles.title}>Response to Deviations</Text>
          <LineChart data={allGraphStates["RD"]}
            verticalLabelRotation={-90}
            horizontalLabelRotation={0}
            withShadow={false}
            width={Dimensions.get('window').width} // from react-native
            height={220}
            segments={allDerivedGraphInfo["RD"].segments}
            chartConfig={LineChartConfig}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }}
            
          />
          {/*<LineChart data={RDState} verticalLabelRotation={110} width={WINDOW_WIDTH} height={220} fromZero={true} withShadow = {false} config={{ renderHorizontalLines: { count: 10 } }} gridMin={0} chartConfig={AppStyles.chartConfigMultiLine}/>*/}
          <LegendSection legendItems={[
            {title: "Commitment to Resilience (CR)", color: `rgb(212,212,212)`},
            {title: "Deference to Expertise (DE)", color: `rgb(235,78,0)`},
          ]}/>
          <View style={styles.seperator} />
          
          <Text style={styles.title}>Hazard Classifications</Text>
          <Pressable onPress={() => setModalVisible(true)}>
          <LineChart data={allGraphStates["KPI"]}
            verticalLabelRotation={-90}
            horizontalLabelRotation={0}
            withShadow={false}
            width={Dimensions.get('window').width} // from react-native
            height={220}
            segments={allDerivedGraphInfo["KPI"].segments}
            chartConfig={LineChartConfig}
            style={{
              marginVertical: 8,
              borderRadius: 16
            }}
            
          />
          {/*<LineChart data={KPIState} verticalLabelRotation={110} width={WINDOW_WIDTH} height={220} fromZero={true} withShadow = {false} config={{ renderHorizontalLines: { count: 10 } }} gridMin={0} chartConfig={AppStyles.chartConfigMultiLine}/>*/}
          <LegendSection legendItems={
            validKPIs.map((value, index) => {
              return({title: value, color: KPIColors[index]})
            })
          }/>
          </Pressable>
        </ScrollView>
        
        <Modal
          style={{flex: 1, justifyContent: 'center'}}
          visible={modalVisible}
          transparent={false}
          onRequestClose={() => setModalVisible(false)}>
          <TouchableOpacity onPress={() => setModalVisible(false)}>
            <Feather name="x" size={30} style={{ color: 'gray', alignSelf: 'flex-start', marginTop: 28, marginLeft: 15}}></Feather>
          </TouchableOpacity>
          <ScrollView>
            <DataTable> 
              <DataTable.Header style={{ }}> 
                <DataTable.Title style={{ minWidth: 65 }}></DataTable.Title>
                {allGraphStates["KPI"]["labels"] && allGraphStates["KPI"]["labels"].map((label, index) => {
                  return(<DataTable.Title key={index} numeric style={allGraphStates["KPI"]["labels"].length > 7 ? {} : { transform: 'rotate(45deg)' }} >{ allGraphStates["KPI"]["labels"].length > 7 ? '' : label}</DataTable.Title>) 
                })}       
              </DataTable.Header> 
              {sortedKPIDatasetDetails.length && sortedKPIDatasetDetails.map((obj, index) => {
                return (
                  <DataTable.Row key={index}> 
                      <DataTable.Title style={{ minWidth: 80 }}>{obj.label}</DataTable.Title>
                      {obj.dataset["data"].map((value, i) => (
                        <DataTable.Cell numeric key={i}>{value}</DataTable.Cell>
                      ))}
                  </DataTable.Row>);
              })}
            </DataTable> 
          </ScrollView>
        </Modal> 
        <Footer admin={admin}/>
      </View>
    );
  }
}

const LineChartConfig = {
  backgroundColor: '#ffffff',
  backgroundGradientFrom: '#ffffff',
  backgroundGradientTo: '#ffffff',
  decimalPlaces: 0, // optional, defaults to 2dp
  color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
  style: {
    borderRadius: 16
  },
  propsForVerticalLabels: {
    translateY: 15,
  }
  }

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  chip_container: {
    backgroundColor: "white",
    paddingVertical: hp(1),
    paddingHorizontal: hp(1),
    alignContent: "center",
    alignItems: "center"
  },
  seperator: {
    height: 1,
    backgroundColor: "#aaaaaa",
    marginVertical: 1,
  },
  title: {
    padding: 10,
    fontSize: 20,
    fontWeight: "bold",
    color: AppStyles.colorSet.mainTextColor,
  },
  numerical_metric_container_left: {
    backgroundColor: "white",
    flex : 0.5,
    alignContent: "center",
    borderColor: '#aaaaaa',
    borderRightWidth: 1,
  },
  numerical_metric_container_right: {
    backgroundColor: "white",
    flex : 0.5,
    alignContent: "center",
  },
  small_title: {
    textAlign: 'center',
    padding: 5,
    fontSize: 17,
    fontWeight: "bold",
    color: AppStyles.colorSet.mainTextColor,
  },
  numerical_headline: {
    textAlign: 'center',
    padding: 5,
    fontSize: 40,
    fontWeight: "bold",
    color: AppStyles.colorSet.mainTextColor,
  },
  Chip:{
    marginHorizontal: hp(0),
    backgroundColor: 'rgba(200,200,200,0.3)',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  ChipSelected:{
    marginHorizontal: hp(0),
    backgroundColor: '#FFC451',
    justifyContent: 'center',
    flexDirection: 'row',
    flex: 1,
    borderRadius: 100,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
  },
  ChipAdd:{
    marginHorizontal: hp(0),
    borderRadius: 100,
    backgroundColor: '#CCCCCC',
    justifyContent: 'center',
    flex: 1,
    borderColor: 'rgb(0,0,0)',
    borderWidth: 1,
    minHeight: hp(3),
  },
  ChipText:{
    marginHorizontal: hp(2),
  },
  ChipDeleteText:{
    marginRight: hp(1),
    borderRadius: 100,
  },
});


export default AnalyticsScreen;
