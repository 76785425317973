import React from 'react';
import { Image, StyleSheet, Text, TouchableHighlight, View } from 'react-native';
import PropTypes from 'prop-types';
import AppStyles from '../AppStyles';
import { Wrap, Box } from 'react-native-flex-layout';

export default class LegendItem extends React.Component {
  render() {
    const { title, color } = this.props;
    return (
      <Box
        style={styles.container}>
        <View style={{ 
            height: 10,
            width: 40,
            margin: 5,
            backgroundColor: color}}/>
        <Text>{title}</Text>
      </Box>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: "white",
  }
});
