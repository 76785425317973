import React from "react";
import { Keyboard, StatusBar, View} from "react-native";
import Navigator from './routes/RootStack';
import * as SplashScreen from 'expo-splash-screen';
import {Amplify } from 'aws-amplify';
import config from './src/aws-exports';
import * as Font from 'expo-font';
import "setimmediate" // workaround to make toasts work on the web portal
import { RootSiblingParent } from 'react-native-root-siblings';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';

Amplify.configure(config);
SplashScreen.preventAutoHideAsync();

export default class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      fontsLoaded: false
    }
  }

  async loadAssetsAsync() {
    
    await Font.loadAsync({
        "Poppins-Medium": require("./assets/fonts/Poppins-Medium.ttf"),
        "Poppins-Bold": require("./assets/fonts/Poppins-Bold.ttf"),
        "Poppins-Thin": require("./assets/fonts/Poppins-Thin.ttf"),
        "Poppins-SemiBold": require("./assets/fonts/Poppins-SemiBold.ttf"),
         // Uncomment if you need other poppins fonts! Should work automatically :)
        "Poppins-Black": require("./assets/fonts/Poppins-Black.ttf"),
        "Poppins-ExtraBold": require("./assets/fonts/Poppins-ExtraBold.ttf"),
        "Poppins-ExtraLight": require("./assets/fonts/Poppins-ExtraLight.ttf"),
        "Poppins-Light": require("./assets/fonts/Poppins-Light.ttf"),
        "Poppins-Regular": require("./assets/fonts/Poppins-Regular.ttf"),
      })
      await SplashScreen.hideAsync();
      this.setState({ fontsLoaded: true });
  };

  componentDidMount() {
    this.loadAssetsAsync()
  }

  handleUnhandledTouches(){
    Keyboard.dismiss()
    return false;
  }

  render() {
    if(this.state.fontsLoaded) {
      return (
        <RootSiblingParent>
        <ActionSheetProvider> 
          <View style={{ flex: 1 }}>
            <StatusBar backgroundColor={'#000'} barStyle='light-content' />
            <Navigator />
          </View>
          </ActionSheetProvider>
          </RootSiblingParent>
      )
      }
  }
}