import React, { useState, useEffect } from "react"
import { View, ScrollView, StyleSheet, Dimensions, Platform, registerCallableModule } from "react-native"
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import { KeyboardAccessoryNavigation } from 'react-native-keyboard-accessory'

import CustomText from "../components/CustomText";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Auth, API } from 'aws-amplify';
import Util from "../util/util.js"
import ChipSection from "../components/ChipSection";
import webStyles from "../style/webStyles.js";
import mobileStyles from "../style/mobileStyles.js";
import {Chip_Styles, Web_Chip_Styles} from "../style/ChipStyles.js"

const AdminOrganizationDashboard = (props) => {
  const { organization, admin } = props.route.params;
  const [KPIChips, setKPIChips] = useState([])
  const [LocationChips, setLocationChips] = useState([])
  const [ShouldUpdateKPIs, setShouldUpdateKPIS] = useState(false)
  const [ShouldUpdateLocations, setShouldUpdateLocations] = useState(false)

  const maxDelete = 1
  const { width } = Dimensions.get('screen');

  useEffect(() => {
    getChips("KPI").then(e => {setKPIChips(() => Util.textOnly(e).sort())});//Display New KPI List
    getChips("Location").then(e => {setLocationChips(() => Util.textOnly(e).sort())});//Display New KPI List
  }, [])


  useEffect(() => {
    if(ShouldUpdateKPIs){
      setShouldUpdateKPIS(false)
      getChips("KPI").then(currentChips => {
        const currentTextOnly = Util.textOnly(currentChips)
        KPIChips.forEach((value, index) => {
            if(!currentTextOnly.includes(value) && !value=="" && !value.length <= 1){
              patchChips(value, "KPI", setKPIChips).then(e => {})
            }
          })
      })
      }     
  }, [KPIChips, ShouldUpdateKPIs])

  useEffect(() => {
    if(ShouldUpdateLocations){
      setShouldUpdateLocations(false)
      getChips("Location").then(currentChips => {
        const currentTextOnly = Util.textOnly(currentChips)
        LocationChips.forEach((value, index) => {
            if(!currentTextOnly.includes(value) && !value=="" && !value.length <= 1){
              patchChips(value, "Location", setLocationChips).then(e => {})
            }
          })
      })
      }     
  }, [LocationChips, ShouldUpdateLocations])

  const patchChips = async (text, value_type, setter) => {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    const requestData = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        organization: organization,
        value_type: value_type,
        value: text
      }
    }
    try{ 
      await API.post('Resty','/items/organization_configuration', requestData)
      getChips(value_type).then(e => {setter(() => Util.textOnly(e).sort())}) //Diplay updated chips
    }
    catch(error){
      Util.printAPIError(error)
    }
  }
   
  const getChips = async (value_type) => {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    const requestData = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        organization: organization,
        value_type: value_type
      }
    }
    try{ 
      const data = await API.get('Resty','/items/organization_configuration/value_type', requestData)
      return(data.items) // This is could perhaps lead to undefined behavior :/
    }
    catch(error){
      Util.printAPIError(error)
    }
    return([])
  }

  //Allow user to add KPI Chip
  const addKPI = () => {
    setShouldUpdateKPIS(true)
  }

  const addLocation = () => {
    setShouldUpdateLocations(true)
  }


  const patchDelete = async (item) => {
    const user = await Auth.currentAuthenticatedUser()
    const token = user.signInUserSession.idToken.jwtToken
    const requestData = {
      headers: {
        Authorization: token
      },
      queryStringParameters: {
        organization: organization,
        timestamp: item.timestamp
      }
    }
    try{
      await API.del('Resty','/items/organization_configuration', requestData)
    }
    catch(error){
      Util.printAPIError(error)
    }
    return([])
  }

  const deleteChips = async (deletedList, value_type) => {
    let deleted = 0
    getChips(value_type).then((chips)=> {
      let to = Util.textOnly(chips)
      deletedList.forEach((value, index) => {
        if(deleted < maxDelete && to.includes(value)){
          deleted += 1
          let fullitem = chips.find(e => e.value == value)
          patchDelete(fullitem)
        }        
      })
    }) 
  }


  const deleteKPI = async (deletedList) => {
    deleteChips(deletedList, "KPI", setKPIChips)
    getChips("KPI").then(e => {setter(() => Util.textOnly(e).sort())})
  }
  

  const deleteLocation = async (deletedList) => {
    deleteChips(deletedList, "Location", setLocationChips)
    getChips("Location").then(e => {setter(() => Util.textOnly(e).sort())})
  }

    return (
        <View style={Platform.OS === 'web' ? webStyles.container : mobileStyles.container}>
          <Header organization={organization} admin={admin} type='pop'/>
          <ScrollView style={styles.innerContainer}>
            {/* KPIs */}
            <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Hazard Classifications</CustomText>
            <CustomText style = {styles.explanationText} font='medium' size={13} color={'#000'}>
              This section contains all Hazard Classification types that your organization can classify reports under.
              Upon removal, a Hazard Classification will no longer be able to be used by users or admins in your organization.
              However, any reports with deleted Hazard Classifications will retain those Hazard Classifications.
              </CustomText>
            <ChipSection styles={Platform.OS === 'web' && width >= 800 ? Web_Chip_Styles : Chip_Styles}
                    deleteChips={deleteKPI} 
                    addChip={addKPI} 
                    doSynchronize={true} 
                    canAdd={true} 
                    canDelete={true} 
                    Chips={KPIChips}
                    sortChips={true}
                    setChips={setKPIChips}
                    maxSelect={maxDelete}/>

<View style={styles.seperator} />
            <CustomText style={styles.heading} font='medium' size={24} color={'rgba(0,0,0,0.35)'}>Locations</CustomText>
            <CustomText style = {styles.explanationText} font='medium' size={13} color={'#000'}>
              This section contains all Locations types that your organization can classify reports as occuring at.
              </CustomText>
            <ChipSection styles={Platform.OS === 'web' & width >= 800 ? Web_Chip_Styles : Chip_Styles}
                    deleteChips={deleteLocation} 
                    addChip={addLocation} 
                    doSynchronize={true} 
                    canAdd={true} 
                    canDelete={true} 
                    Chips={LocationChips}
                    sortChips={true}
                    setChips={setLocationChips}
                    maxSelect={maxDelete}/>
          </ScrollView>
          <Footer admin={admin} />
          <KeyboardAccessoryNavigation androidAdjustResize avoidKeyboard />
        </View>
    )
}

const styles = StyleSheet.create({
  innerContainer: {
    backgroundColor: "#FFF",
    flex: 1,
    margin: 15,  
  },
  seperator: {
    height: 1,
    backgroundColor: 'rgba(0,0,0,.3)',
    marginVertical: 1,
    marginTop: 50,
    marginBottom: 5
  },
  orangeContainer: {
      borderRadius: 12,
      backgroundColor: 'rgba(255, 196, 81, 0.3)',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: wp(3.2),
      marginTop: hp(1),
      maxHeight: hp(40),
      minHeight: hp(17.8),
      borderColor: 'rgb(100,100,100)',
      borderWidth: 1,
  },
  orangeContainer2: {
      borderRadius: 12,
      backgroundColor: 'rgba(255, 196, 81, 0.3)',
      alignItems: 'center',
      paddingVertical: 10,
      paddingHorizontal: wp(3.2),
      marginTop: hp(1),
      maxHeight: hp(40),
      borderColor: 'rgb(0,0,0)',
      borderWidth: 1,
  },
  button: {
      cursor: 'pointer',
      backgroundColor: '#FFC451',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
      height: hp(6.8),
      width: '70%',
      alignSelf: 'center',
      marginTop: hp(2.5),
  },
  buttonDelete: {
      cursor: 'pointer',
      backgroundColor: '#eb4129',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 12,
      height: hp(6.8),
      width: '50%',
      alignSelf: 'center',
      marginTop: hp(2.5),
  },
  buttonAdd: {
    cursor: 'pointer',
    backgroundColor: '#11FF11',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    height: hp(6.8),
    width: '50%',
    alignSelf: 'center',
    marginTop: hp(2.5),
},
  heading: {
      marginTop: hp(1),
  },
  KPIChip:{
      marginHorizontal: hp(0),
      backgroundColor: 'rgba(255,196,81,0.3)',
      justifyContent: 'center',
      flexDirection: 'row',
      flex: 1,
      borderRadius: 100,
      borderColor: 'rgb(0,0,0)',
      borderWidth: 1,
  },
  KPIChipSelected:{
      marginHorizontal: hp(0),
      backgroundColor: '#FFC451',
      justifyContent: 'center',
      flexDirection: 'row',
      flex: 1,
      borderRadius: 100,
      borderColor: 'rgb(0,0,0)',
      borderWidth: 1,
  },
  KPIChipAdd:{
      marginHorizontal: hp(0),
      borderRadius: 100,
      backgroundColor: '#CCCCCC',
      justifyContent: 'center',
      flex: 1,
      borderColor: 'rgb(0,0,0)',
      borderWidth: 1,
  },
  KPIText:{
      marginHorizontal: hp(2),
  },
  KPIDeleteText:{
      marginRight: hp(1),
      borderRadius: 100,
  }
})

export default AdminOrganizationDashboard;