import {React, useEffect, useState } from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import DropDownPicker from 'react-native-dropdown-picker'


const screenWidth = Dimensions.get('window').width


export default function UpdateRoles({ user, roles, edit, postUpdateAction}) {

  const defaultRoles = [{'label': 'Reporter', 'value': 'Reporter'}, {'label': 'Admin', 'value': 'Analyst'}]
  const [roleOpen, setRoleOpen] = useState(false)
  const [roleValue, setRoleValue] = useState(roles ? roles : [])
  const [roleFilter, setRoleFilter] = useState(defaultRoles)


  const setValue =(value) => {
    if (edit) { 
      setRoleValue(value) 
    }
  }

  useEffect(() => {
    if (edit && roles && roles != roleValue) {
      postUpdateAction(user, roleValue)
    }
  }, [roleValue]);

  return (
    <DropDownPicker
      style={{
        minHeight: 28,
        maxHeight: 28,
        marginTop: 5,
        border: 0
      }}
      textStyle={{
        fontSize: 14
      }}
      badgeStyle={{
        height: 20,
        disabled: !edit,
        cursor: edit ? 'pointer' : 'default'
      }}
      zIndex={9}
      multiple={true}
      min={0}
      open={roleOpen}
      value={roleValue}
      items={roleFilter}
      setOpen={setRoleOpen}
      setValue={setValue}
      setItems={setRoleFilter}
      placeholder="No role set"
      dropDownDirection="TOP"
      disabled={!edit}
      showArrowIcon={edit}
      selectable={edit}
      maxHeight={158}
      extendableBadgeContainer={true}
    />
  );
}
const styles = StyleSheet.create({
  button: {
    cursor: 'pointer',
    marginVertical: 10,
    borderRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 5,
    width: 95,
    backgroundColor: '#FFC451'
  },
  buttonText: {
    fontSize: 12,
    fontWeight: '600',
  }
});