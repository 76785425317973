import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TouchableWithoutFeedback, Platform, Keyboard } from 'react-native';
import { Auth } from 'aws-amplify';
import { SafeAreaView } from 'react-native-safe-area-context';
import AppTextInput from '../components/AppTextInput';
import AppButton from '../components/AppButton';
//import { Root, Popup } from 'popup-ui'
import Toast from 'react-native-root-toast';
import CustomText from "../components/CustomText";
import alert from '../components/alert'
import Util from "../util/util.js"
const ConfirmSignUp = (props) => {
  const [username, setUsername] = useState('');
  const [authCode, setAuthCode] = useState('');
  async function confirmSignUp() {
    try {
      await Auth.confirmSignUp(username, authCode);
      console.log('✅ Code confirmed');
      /*
      Popup.show({
        type: 'Success',
        title: 'Verification Code Valid',
        button: false,
        textBody: 'Verification code has been confirmed!',
        buttontext: 'Ok',
        callback: () => Popup.hide()
      })
      */

      Toast.show('✅ Verification Code Valid!', {
        backgroundColor: "#259e23",
        duration: 5000,
        textColor: "#fff",
      });

      props.navigation.navigate('Login')
    } catch (error) {
      console.log(
        '❌ Verification code does not match. Please enter a valid verification code.',
        error.code
      );
      /*
      Popup.show({
        type: 'Danger',
        title: 'Invalid Verification Code',
        button: false,
        textBody: 'Verification code does not match. Please enter a valid verification code.',
        buttontext: 'Ok',
        callback: () => Popup.hide()
      })
      */
      alert("Error", "❌ Invalid Verification Code.")
    }
  }
  return (
    <SafeAreaView style={styles.safeAreaContainer}>
      <TouchableWithoutFeedback onPress={Platform.OS != 'web' ? Keyboard.dismiss : console.log("")} accessible={false} >
      <View style={styles.container}>
        <Text style={styles.title}>Confirm Sign Up</Text>
        <CustomText font='medium' size={22} color='#000'>Check your email for the verification code</CustomText>
        <AppTextInput
          value={username}
          onChangeText={text => setUsername(text)}
          leftIcon="account"
          placeholder="Confirm email"
          autoCapitalize="none"
          keyboardType="email-address"
          textContentType="emailAddress"
        />
        <AppTextInput
          value={authCode}
          onChangeText={text => setAuthCode(text)}
          leftIcon="numeric"
          placeholder="Enter verification code"
          keyboardType="numeric"
        />
        <AppButton title="Confirm Sign Up" onPress={confirmSignUp} />
      </View>
      </TouchableWithoutFeedback>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
    safeAreaContainer: {
      flex: 1,
      backgroundColor: 'white'
    },
    container: {
      flex: 1,
      alignItems: 'center'
    },
    title: {
      fontSize: 20,
      color: '#FFC451',
      fontWeight: '500',
      marginVertical: 15
    }
  });

  export default ConfirmSignUp;