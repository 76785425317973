import React from "react";
import { View } from "react-native";
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";
import CustomText from "../components/CustomText";

const Footer = (props) => {
    const admin = props.admin;
    return (
        <View style={{ paddingHorizontal: wp(2.7), paddingVertical: hp(.1), paddingTop: hp(.1), backgroundColor: '#000', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <CustomText style={{color: 'lightgray'}}>({admin === true ? 'Admin' : 'User'} Portal)</CustomText>
        </View>
    )    
}

export default Footer;