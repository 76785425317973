import {StyleSheet} from 'react-native';
import { heightPercentageToDP as hp, widthPercentageToDP as wp } from "react-native-responsive-screen";

export const Chip_Styles = StyleSheet.create({
    buttonDelete: {
        cursor: 'pointer',
        backgroundColor: '#eb4129',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        height: hp(6.8),
        width: '50%',
        alignSelf: 'center',
        marginTop: hp(2.5),
      },
      buttonAdd: {
        cursor: 'pointer',
        backgroundColor: '#ffc107',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        height: hp(6.8),
        width: '50%',
        alignSelf: 'center',
        marginTop: hp(2.5),
      },
      Chip:{
        marginHorizontal: hp(0),
        backgroundColor: 'rgba(255,196,81,0.3)',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: 100,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
        minHeight: 30,
      },
      ChipSelected:{
        marginHorizontal: hp(0),
        backgroundColor: '#FFC451',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: 100,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
      },
      ChipAdd:{
        marginHorizontal: hp(0),
        borderRadius: 100,
        backgroundColor: '#CCCCCC',
        justifyContent: 'center',
        flex: 1,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
        minHeight: 30,
      },
      ChipText:{
        marginHorizontal: hp(2),
        alignSelf: "center"
      },
      ChipDeleteText:{
        marginRight: hp(1),
        borderRadius: 100,
      },
  })
  
  export const Web_Chip_Styles = StyleSheet.create({
      buttonDelete: {
        cursor: 'pointer',
        backgroundColor: '#eb4129',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        height: hp(6.8),
        width: '50%',
        alignSelf: 'center',
        marginTop: hp(2.5),
      },
      buttonAdd: {
        cursor: 'pointer',
        backgroundColor: '#ffc107',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 12,
        height: hp(6.8),
        width: '50%',
        alignSelf: 'center',
        marginTop: hp(2.5),
      },
      Chip:{
        cursor: 'pointer',
        marginHorizontal: hp(0),
        backgroundColor: 'rgba(255,196,81,0.3)',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: 100,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
        minHeight: 30
      },
      ChipSelected:{
        cursor: 'pointer',
        marginHorizontal: hp(0),
        backgroundColor: '#FFC451',
        justifyContent: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: 100,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
      },
      ChipAdd:{
        marginHorizontal: hp(0),
        borderRadius: 100,
        backgroundColor: '#CCCCCC',
        justifyContent: 'center',
        flex: 1,
        borderColor: 'rgb(0,0,0)',
        borderWidth: 1,
        minHeight: 30
      },
      ChipText:{
        marginHorizontal: hp(2),
        alignSelf: "center"
      },
      ChipDeleteText:{
        marginRight: hp(1),
        borderRadius: 100,
      },
  })

  export const AI_Chip_Styles = StyleSheet.create({
    Chip:{
      marginHorizontal: hp(0),
      backgroundColor: 'rgba(102,153,255,0.3)',
      justifyContent: 'center',
      flexDirection: 'row',
      flex: 1,
      borderRadius: 100,
      borderColor: 'rgb(0,0,0)',
      borderWidth: 1,
      minHeight: 30
    },
    ChipText:{
      marginHorizontal: hp(2),
      alignSelf: "center"
    },
})